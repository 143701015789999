import React from "react";

import Link from "next/link";
import Image from "next/image";

import { ARTICLES } from "./constants";

import { Icon } from "@/components/Icon";

const Insights = () => {
  return (
    <section
      id="insights"
      className="w-full bg-neutral-700 pt-[3.75rem] pb-[4.375rem] lg:pt-[4.5rem] text-white overflow-hidden relative"
    >
      <span
        id="radial-gradient"
        className="h-[323px] w-[1191px] lg:w-[2283px] lg:h-[619px] lg:-bottom-[350px] bg-insights-radial absolute left-1/2 -bottom-[205px] -translate-x-1/2 flex"
      />
      <div className="max-w-7xl mx-auto px-10 lg:px-8 flex flex-col gap-12 relative z-10">
        <div className="flex flex-col text-left gap-4 lg:gap-8">
          <h2 className="text-white font-bold text-xl md:text-3xl lg:text-[2.5rem]">
            Insights & <span className="text-primary-900">Inspirations</span>
          </h2>
          <span className="text-xs md:text-base lg:max-w-[70%] font-inter">
            Explore insights on brands transitioning to Web3 and uncover how
            Mintology can guide your enterprise effectively through the Web3
            landscape.
          </span>
        </div>
        <ul className="flex flex-col lg:flex-row gap-5 lg:grid lg:grid-cols-4 lg:gap-[3.4rem]">
          {ARTICLES.map((article, i) => (
            <li key={i}>
              <Link
                target="_blank"
                href={article.url}
                rel="noreferrer noopener nofollow"
                className="w-full flex gap-3 md:gap-6 lg:gap-8 justify-between rounded lg:rounded-none overflow-hidden lg:flex-col"
              >
                <div className="min-w-[126px] w-[40%] relative aspect-square lg:aspect-[283/380] lg:rounded-[1.25rem] overflow-hidden lg:w-full">
                  <Image
                    src={article.image}
                    alt={article.title}
                    fill
                    className="object-cover"
                  />
                </div>
                <aside className="flex flex-col justify-center gap-3 font-inter">
                  <h3 className="font-bold text-lg md:text-xl lg:text-2xl flex justify-between">
                    {article.title} <Icon name="arrow-diagonal" />
                  </h3>
                  <span className="text-xs md:text-sm">
                    {article.description}
                  </span>
                </aside>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Insights;
